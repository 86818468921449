import React from 'react'
import '../components/bootstrap.min.css';
import '../components/vendor/tether/tether.min.css';
import '../components/vendor/bootstrap/css/bootstrap.min.css';
import '../components/css/fonts/express-icons.css';
import '../components/css/card.css';

import '../components/vendor/font-awesome/css/font-awesome.min.css';
import '../components/vendor/bootstrap/css/glyphicon.css';
import '../components/vendor/cubeportfolio/css/cubeportfolio.min.css';

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import '../components/vendor/owl-carousel/owl.theme.css';
import '../components/vendor/owl-carousel/owl.carousel.css';
import '../components/vendor/magnific-popup/magnific-popup.css';
import '../components/vendor/lite-tooltip/css/litetooltip.css';
import '../components/vendor/PricingTablesInspiration/css/component.css';
import '../components/vendor/PricingTablesInspiration/css/icons.css';
import '../components/vendor/PricingTablesInspiration/css/demo.css';
//Theme CSS
import '../components/css/main.css';
import '../components/css/main-shortcodes.css';
import '../components/css/header.css';
import '../components/css/form-element.css';
import '../components/css/animation.css';
import '../components/css/bg-stylish.css';
import '../components/css/blog.css';
import '../components/css/streamline-icon.css';
import '../components/css/responsive.css';
import '../components/css/utilities.css';
import '../components/css/portfolio.css';
import '../components/css/font-icons.css';
import '../components/css/skins/default.css';

//Theme Custom CSS
import '../components/css/custom.css';

import Footer from "../components/footer";
import Header from "../components/header";


const IndexPage = () => (
<div class="wrapper">
<Header/>
<div class="page">
<div class="page-inner pt-10 pb-25">

   <div >
      <div class="container">
        <div class="row text-black text-center">
           <div class="col-md-12">
              <h2>Search templates</h2>
           </div>

           <div class="col-12">
    	    <div id="custom-search-input">
                <div class="input-group">
                    <input type="text" class="search-query form-control" placeholder="Leaflets Posters Cards" style={{backgroundColor: ""}}/>
                    <span class="input-group-btn">
                        <button type="button" disabled>
                            <span class="fa fa-search"></span>
                        </button>
                    </span>
                </div>
            </div>
        </div>

           <div class="col-md-12">

              <div class=" mt-40">
                 <a href="#" class="btn btn-primary btn-rounded item-shadow-5x"><i class="fa fa-search"></i> Search</a>
              </div>
           </div>
        </div>
     </div>
       

     <div class="container">
      <div class="row" id="ads">
      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    

  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    
      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>



      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>



      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>



      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    

  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    

  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    
    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>


    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>

    
  

      <div class="col-3" style={{marginTop: "2%"}}>
      <a href="https://www.google.com">
      <div class="weather-card one">
      <div class="top">
      <div class="wrapper">

      <a href="www.google.com" class="location">Dhaka, Bangladesh</a>

      </div>
      </div>
      <div class="bottom">
      <div class="wrapper">
      <div class="item-content-block tags">
      <a href="#">lorem</a> <a href="#">loremipse</a>
      <a href="#">lorem</a> <a href="#">loremipse</a><a href="#">lorem</a> <a href="#">loremipse</a>
      </div>
      </div>
      </div>
      </div>
      </a>
      </div>
</div>

</div>

         
      <div class="container" style={{paddingTop: "3%"}}>
         <div class="row">
            <div class="col-md-12">
               <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                     <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                     <li class="page-item"><a class="page-link" href="#">1</a></li>
                     <li class="page-item"><a class="page-link" href="#">2</a></li>
                     <li class="page-item"><a class="page-link" href="#">...</a></li>
                     <li class="page-item"><a class="page-link" href="#">15</a></li>
                     <li class="page-item"><a class="page-link" href="#">15</a></li>
                     <li class="page-item"><a class="page-link" href="#">Next</a></li>
                  </ul>
               </nav>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
<Footer/>
</div>    
)
export default IndexPage
